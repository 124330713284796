/* ========================================

    list
        リストのスタイル

    INDEX
        - text list
        - thumb list
        - circle list

======================================== */

.common-list {
  // 頭に※などをつける場合
  li {
    margin-left: 1.2em;
    text-indent: -1.2em;
    list-style-type: none !important;
  }

  // リスト同士の行間を開けたい場合
  &--padding {
    li {
      padding: 10px 0;
    }
  }

  // 丸ポチ
  &--disc {
    li {
      margin-left: 1.2em;
      list-style-type: disc;
    }
  }

  // 数字
  &--num {
    li {
      margin-left: 1.2em;
      list-style-type: decimal;
    }
  }
}


.common-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    padding: 10px;
    text-align: left;
    background: $C_BLACK;
    border: 1px solid $C_GRAY2;
    color: $C_WHITE;
  }

  td {
    padding: 10px;
    text-align: left;
    background: $C_WHITE;
    border: 1px solid $C_GRAY2;
  }
}

.table-scroll {
  min-width: 700px;
}

.history-table,
.cart-table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  &__id {
    @include mq-s {
      background-color: #000;
      color: #FFF;
    }
  }

  th {
    padding: 10px;
    text-align: left;
    background: $C_BLACK;
    border: 1px solid $C_GRAY2;
    color: $C_WHITE;
  }

  td {
    padding: 10px;
    border: 1px solid $C_GRAY2;

    @include mq-s {
      padding: 6px 6px;
      display: block;
      width: 100%;
      border: none;
    }
  }

  tr {
    @include mq-s {
      border: 1px solid $C_GRAY2;
    }
  }
}

.align-dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  dt {
    text-align: left;

    @include mq-s() {
      border-top: none;
      width: 100%;
    }
  }

  dd {
    text-align: right;

    @include mq-s() {
      border-top: none;
      width: 100%;
      padding: 5px 0px 10px;
      text-align: left;
    }
  }
}

.common-dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  dt {
    width: 20%;
    padding: 20px 0;
    font-weight: 700;

    @include mq-m() {
      width: 25%;
    }
    @include mq-s() {
      width: 100%;
      padding: 10px 5px 0;
      font-weight: bold;
    }
  }

  dd {
    width: 79%;
    padding: 20px 0;

    @include mq-m() {
      width: 74%;
    }
    @include mq-s() {
      border-top: none;
      width: 100%;
      padding: 5px 5px 10px;
    }
  }
}


.flex-list {
  width: 100%;
  display: flex;

  li {
    list-style-type: none;
    padding-right: 15px;

    &.flex-list--item {
      display: flex;
      align-items: center;
    }
  }

  .form-select,
  .login-dl__select {
    color: $C_BLACK !important;
    width: 100%;
  }
}

.conveni-select {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.no-bullet {
  list-style-type: none;
}
