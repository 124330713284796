.history-page {
  overflow: hidden;

  &__box {
    display: flex;
    flex-wrap: wrap;

    &-switch {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;

      &--item {
        display: inline-block;
        background-color: $C_WHITE;
        margin: 0 1% 5px;
        width: 48%;
        padding: 12px 5px;
        font-family: $ENG_FONT_FAMILY;
        font-weight: 700;
        text-align: center;
        display: flex;
        justify-content: center;
        border: 1px solid #8F939A;
      }
    }

    & .active {
      background-color: #000;
      color: #FFF;
    }
  }

  &__table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    overflow: hidden;

    & a {
      text-decoration: underline;
    }

    th {
      padding: 10px;
      text-align: left;
      background: $C_BLACK;
      border: 1px solid $C_GRAY2;
      color: $C_WHITE;

      @include mq-mb {
        font-size: 1.2rem;
        padding: 6px 8px;
        text-align: center;
        min-width: 40px;
      }
    }

    td {
      padding: 10px;
      border: 1px solid $C_GRAY2;

      @include mq-mb {
        font-size: 1rem;
        padding: 6px 5px;
        word-break: break-all;
      }
    }

    tr {
      @include mq-mb {
        border: 1px solid $C_GRAY2;
      }

      &.link-tr {
        transition: opacity 0.1s ease;
        @include hover {
          opacity: 0.7;
          background-color: rgba($C_GRAY2, .2);
        }
      }
    }
  }

  &__total {
    width: 100%;
    border: 2px solid #000;
    min-height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;

    dt {
      width: 20%;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      overflow: hidden;

      @include mq-mb {
        width: 50%;
        font-size: 1.2rem;

        align-items: center;
        justify-content: space-between;
        &:first-child {
          border-right: 1px dashed #000;
        }
        & .total-label {
          display: flex;
          align-items: center;
        }

        & .total-value {
          display: flex;
          align-items: center;
        }
      }
    }

    dd {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: end;
      text-align: right;
      padding: 5px 10px;
      border-right: 1px dashed #000;

      @include mq-mb {
        font-size: 1.2rem;
      }
    }
  }

}