.mypage--edit {
  @include mq-s {
    &__birthday-list {
      select {
        padding: 10px 28px 10px 10px;
      }
    }
    &__year {
      min-width: 113px;
      flex: 1;
    }
    &__month {
      min-width: 95px;
      flex: 1;
    }
    &__day {
      min-width: 80px;
      flex: 1;
    }
  }
}

.change-password {
  &__password {
    &__input {
      height: 40px;
    }

    .form-password__append {
      height: 40px;
      border-color: $C_BLACK;
    }
  }
}
