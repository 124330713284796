@charset "UTF-8";
@import "../common/variables";
@import "../common/mixins";

/* Slider */
.slick-list {
  .slick-loading & {
    background: #fff center center no-repeat;
  }

  .slick-slide {

    img {
      width: 95%;
      margin: 0 auto;
    }
  }

}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  border: none;
  outline: none;
  z-index: 100;
  padding: 0;
  margin: 0;

  @include mq-s {
    height: 30px;
    width: 30px;
  }
}

.slick-prev {
  left: 24%;

  @include mq-m {
    left: 10%;
  }
  @include mq-s {
    left: 5px;
  }

  &:before {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    background: center center no-repeat;
    // background: url("../../images/arw-left.svg") center center no-repeat;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;

    @include mq-s {
      height: 30px;
      width: 30px;
    }
  }
}

.slick-next {
  right: 24%;

  @include mq-m {
    right: 10%;
  }
  @include mq-s {
    right: 5px;
  }

  &:before {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    background: center center no-repeat;
    // background: url("../../images/arw-right.svg") center center no-repeat;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;

    @include mq-s {
      height: 30px;
      width: 30px;
    }
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 20px;
}

.slick-dots {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;

  li {
    width: 10px !important;
    height: 10px !important;

    button {
      width: inherit !important;
      height: inherit !important;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: inherit !important;
        height: inherit !important;
        line-height: 10px !important;
        background: $C_SUB2;
        border-radius: 100px;
        opacity: 0.5;
      }
    }

    &.slick-active,
    &:hover,
    &:focus {
      button {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.mov-slider {
  .slick-next,
  .slick-prev {
    width: 70px;
    height: 70px;
    top: 55%;
    @include mq-mb {
      width: 50px;
      height: 50px;
    }

    &:before {
      width: 70px;
      height: 70px;
      @include mq-mb {
        width: 50px;
        height: 50px;
      }
    }
  }

  .slick-next {
    right: 2%;
  }

  .slick-prev {
    left: 2%;
  }

  .slick-disabled {
    width: 0;
    height: 0;

    &:before {
      display: none;
    }
  }
}