/* ========================================

    cart
         cartページのみで使うスタイル

    INDEX
        - table

======================================== */

/* ========================================
    table
======================================== */
.cart-table {

  td {
    max-width: 400px;
  }

  &__al-r {
    @include mq-s {
      text-align: right;
    }
  }

  &__image {
    height: 120px;
    width: auto;
    max-width: 250px;
    object-fit: contain;

    @include mq-s {
      max-width: calc((100vw - 70px) / 2);
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      @include mq-s {
        margin-top: 20px;
      }
    }
  }

  &__price {
    &.pc {
      @include mq-s {
        display: none;
      }
    }

    &.sp {
      display: none;

      @include mq-s {
        display: block;
      }
    }
  }

  &__input {
    width: 60px;
    height: 40px;
    border: 1px solid #000;
    padding-left: 10px;
  }

  &__label {
    display: inline-block;
    padding: 3px 12px;
    background-color: #000;
    margin-right: 15px;
    color: #FFF;

    &-wrapper {
      width: 265px;
      display: inline-block;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &__note {
    display: none;
    font-size: 10px;

    &.pc {
      font-size: 12px;

      @include mq-pc {
        display: block;
      }
    }

    &.tb {
      line-height: 1.5;
      margin-top: 10px;

      @include mq-m {
        display: block;
      }
    }

    &.sp {
      @include mq-s {
        display: block;
      }
    }
  }

  .flex {
    display: flex;

    &.between {
      justify-content: space-between;
      align-items: center;
    }
  }
}


#popup-security {
  width: 95%;
  margin: 0 auto;

  .security-img {
    width: 72%;
    max-width: 400px;
    margin: 0 auto;
  }
}
