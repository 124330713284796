/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper
        - content

======================================== */

body {
  min-height: 100vh;

  &.no_scroll {
    overflow: hidden;
    touch-action: none;
    --ms-touch-action: none;
  }
}

/* ========================================
    wrapper
======================================== */
.wrapper {
  width: 100%;
  //padding-top: 120px;
  //@include mq-s {
  //  padding-top: 60px;
  //}
  min-height: 100vh;
}


/* ========================================
    content
======================================== */
.content {
  width: 100%;

  &__inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 1000px) {
      width: calc(100% - 50px);
    }

    @include mq-s {
      width: calc(100% - 20px);
      .static-page & {
        width: auto;
        margin: 0 10px;
      }
    }

    &--editor {
      & * {
        font-size: revert;
        font-weight: revert;
        text-align: revert;
        font-style: revert;
        list-style: revert;
        border: revert;
        vertical-align: revert;
        outline: revert;
        text-decoration: revert;
        color: revert;
      }

      //@include keep-proportion-box;

      p {
        //@include keep-font-size(15, 13, 20, 20);
        font-size: 13px;
      }

      h2 {
        //@include keep-font-size(20, 16, 20, 20);
        font-size: 20px;
      }

      h3 {
        //@include keep-font-size(18, 15, 20, 20);
        font-size: 18x;
      }

      ul, ol {
        margin-left: 8px;
        list-style-position: outside;
      }

      li {
        //@include keep-font-size(15, 13)
      }

      & a {
        text-decoration: underline;
      }
    }
  }

  &__title {

    &-en {
      font-size: 5rem;
      width: 100%;
      display: block;
      line-height: 100%;
    }

    &-jp {
      font-size: 1.4rem;
      width: 100%;
    }
  }
}

.bg-gray {
  background-color: $C_GRAY;
}

.bg-white {
  background-color: #FFF;
}

.bg-black {
  background-color: #000;
}

.scroll-x {
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* ========================================
    form系
======================================== */
input[type="text"]:not(.form-password__input), input[type="number"], textarea {
  -webkit-appearance: none;
  border-radius: 0;
  border: 1px solid #000;
}

/* ========================================
    テキストのスタイル
======================================== */
.font-en {
  font-family: $ENG_FONT_FAMILY;
}

.lh-1 {
  line-height: 100%;
}

/* ========================================
   ボックスシャドウ
======================================== */
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0 20px 50px;
}

/* ========================================
    アコーディオン
======================================== */
.information {
  &__heading {
    width: 100%;
    font-weight: 500;
    display: flex;
    //flex-wrap: wrap;
    background-color: #000;
    color: #FFF;
    transition: all 0.3s ease 0s;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    &:after {
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      color: #FFF;
      font-weight: 900;
      display: block;
      position: absolute;
      right: 24px;
      top: calc(50% - 15px);
      font-size: 1.8rem;
      transition: 0.3s ease;

      @include mq-mb {
        font-size: 1.2rem;
        right: 10px;
        top: calc(50% - 11px);
      }
    }

    &.open {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  &__type {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.8rem;
    background-color: $C_RED;
    padding: 4px 12px;

    @include mq-s {
      font-size: 1.6rem;
    }
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.25;
    padding: 4px 30px 4px 15px;

    @include mq-s {
      font-size: 1.3rem;
      line-height: 1.25;
    }

    &--date {
      display: inline-block;
      font-size: 1.5rem;
      margin-right: 15px;

      @include mq-mb {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }

  &__subtitle {
    @include mq-mb {
      width: 100%;
    }
  }

  &__detail {
    display: block;
    background-color: $C_WHITE;
    color: $C_BLACK;
    border: 1px solid $C_BLACK;
    border-top: 0;

    line-height: 1.45;

    @include mq-s {
      line-height: 1.45;
    }

    &__inner {
      padding: 18px 10px;
      border-bottom: 1px solid #000;
      &:last-child {
        border-bottom: none;
      }
      &_title {
        cursor: pointer;
        text-decoration: underline;
      }
      &_content {
        margin-top: 16px;
      }
    }
  }
}

//.whole-information {
//  .information {
//    &__title--date  {
//      @include keep-font-size(15, 15, 0, 133.438)
//    }
//    &__subtitle {
//      @include keep-font-size(14, 13, 0, 133.438)
//    }
//    &__detail {
//      @include keep-font-size(15, 14, 0, 42)
//    }
//  }
//}

/* ========================================
    一覧ページ（3カラム）
======================================== */
.three-column {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__item {
    width: calc((100% - 100px) / 3);
    margin-bottom: 30px;
    position: relative;

    @media screen and (min-width: 768px) {
      &:not(:nth-of-type(3n)) {
        margin-right: 50px;
      }
    }
    @include mq-s {
      width: calc((100% - 14px) / 2);

      &:not(:nth-of-type(2n)) {
        margin-right: 14px;
      }
    }

    a {
      display: block;
      color: $C_BLACK;
    }
  }
}

.article {
  &__link {
    height: 100%;
    display: flex !important;
    flex-direction: column;
  }

  &__logo {
    &--arrange {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 85px;
      z-index: 30;
    }

    &--recommend {
      position: absolute;
      left: -12px;
      top: -12px;
      width: 32px;
      z-index: 30;
      animation: 2.5s linear infinite kirakira;

      @include mq-s {
        left: -10px;
        top: -10px;
        width: 30px;
      }
    }
  }

  &__thumbnail {
    width: 293px;
    height: 209.03px;
    position: relative;
    overflow: hidden;

    @include loadingBb($LOADING_ICON_EVENT_SIZE);

    @include mq-s {
      height: auto;
      width: 100%;
      padding-top: 75%;
      position: relative;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &--blur {
      width: 100%;
      height: 100%;
      filter: blur(7px);
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
    }
  }

  &__title_small {
    //@include keep-font-size(14,12);
    line-height: 1.2;
    margin: 10px 0;
    color: $C_BLACK;
    font-weight: 700;

    //& br {
    //  display: none;
    //}

    & pre {
      white-space: inherit !important;
    }
  }

  &__title_arrange {
    line-height: 2rem;
    color: $C_BLACK;
    position: relative;
    left: 0;
    bottom: 0;
    margin: -20px 0 0;
    width: 100%;
    z-index: 10;
    font-weight: 700;
    text-align: left;
    & > div {
      background: rgba(255, 255, 255, 0.8);
    }

    & h3 {
      color: $C_BLACK;
      font-weight: 700;
      padding-bottom: 10px;
      text-align: right;
    }

    & .article__sale-term {
      @include keep-font-size(14,12);
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__label {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    margin-top: 1px;

    span {
      display: inline-block;
      min-width: 55px;
      padding: 2px 10px 3px;
      border: 1px solid $C_RED;
      font-weight: bold;
      line-height: 1em;
      text-align: center;
      font-size: 1.2rem;
    }

    &--new {
      color: $C_RED;
      font-family: $ENG_FONT_FAMILY;
      background-color: #FFF;
    }

    &--3day {
      color: $C_RED;
      background-color: rgba(185, 0, 0, 0.3);
    }

    &--today {
      color: #FFF;
      background-color: $C_RED;
    }
  }

  &__sale-term {
    color: #8f939a;
    margin: auto 0 5px;

    & span {
      display: block;
      @include mq-s {
        font-size: 1.2rem;
      }
    }
  }
}


@keyframes kirakira {
  0% {
    filter: brightness(160%);
  }
  50% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(160%);
  }
}


/* ========================================
    border
======================================== */
.border-red {
  border: 1px solid $C_RED;
  width: 100%;
  padding: 20px 50px;

  @include mq-s {
    padding: 10px 30px;
  }
}

.pre-line {
  white-space: pre-line;
}

.pl-1 {
  padding-left: 10px;
}
