/* ========================================

    title
        見出しのパーツ

    INDEX
        - section
        - main
        - sub

======================================== */

/* ========================================
    global
        ページ見出し
======================================== */
.page-title {
  padding: 0 20px 60px;
  text-align: center;

  @include mq-m {
    padding: 0 20px 40px;
  }
  @include mq-s {
    padding: 0 20px 30px;
  }

  &--en {
    font-family: $ENG_FONT_FAMILY;
    font-size: 5rem;
    width: 100%;
    display: block;
    line-height: 110%;

    @include mq-m {
      font-size: 4rem;
    }
    @include mq-s {
      font-size: 3rem;
    }
  }

  &--jp {
    font-size: 1.4rem;
    font-weight: 700;
    width: 100%;
  }
}

/* ========================================
    section
        大見出し
======================================== */
.section-title {
  display: block;
  width: 100%;
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 700;
  background: $C_GRAY;
  border-bottom: 3px solid $C_SUB2;
  position: relative;
  padding: 10px;
  margin-bottom: 30px;

  @include mq-s {
    font-size: 1.6rem;
    line-height: 1.25;
    margin-bottom: 20px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 80px;
    bottom: -3px;
    left: 0;
    background: $C_RED;
  }
  &.no-bg-color {
    background: none !important;
  }
}

.goods-title {
  width: 100%;
  border-left: 10px solid $C_SUB2;
  padding: 4px 0 5px 10px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq-s {
    display: block;
    margin-bottom: 20px;
  }

  &__text {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 700;

    @include mq-s {
      font-size: 1.65rem;
    }
  }

  &__label {
    display: inline-block;
    width: 125px;
    padding: 5px 0px;
    font-weight: bold;
    line-height: 1em;
    text-align: center;
    color: $C_RED;
    font-family: $ENG_FONT_FAMILY;
    background-color: $C_WHITE;
    border: 1px solid $C_RED;
    font-size: 1.5rem;
    margin-left: 10px;

    @include mq-s {
      display: block;
      margin-left: 0;
      margin-top: 5px;
    }
  }
}

/* ========================================
    main
        中見出し
======================================== */
.main-title {
  display: block;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.25;
  border-bottom: 1px solid $C_SUB2;
  padding: 10px 0;
  margin-bottom: 20px;

  @include mq-s {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 15px;
  }
}

/* ========================================
    sub
        子見出し
======================================== */
.sub-title {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.25;
  background: $C_GRAY;
  padding: 5px;
  margin-bottom: 10px;

  @include mq-s {
    font-size: 1.45rem;
    line-height: 1.25;
  }
}
