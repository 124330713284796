/* ========================================
    
    header
        ヘッダーのパーツ

    INDEX
        - common

======================================== */

/* ========================================
    common
======================================== */
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2000;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 15%);
  padding: 20px 0;

  &.under_space {
    margin-bottom: 30px;
  }

  @include mq-s {
    padding: 10px 10px;
  }

  &__content__wrapper {
    display: flex;
    flex-direction: column;
    // margin-bottom: 10px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    height: 80px;
    margin: 0 auto;

    @include mq-s {
      align-items: flex-start;
      height: auto;
      margin: 0 auto;
    }
  }

  &__artist-name {
    display: inline-block;
    height: 100%;
    width: 330px;

    @include mq-s {
      height: 40px;
      width: 165px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  &__nav {
    display: inline-block;

    @include mq-s {
      margin-top: 6px;
    }

    ul {
      display: flex;
      align-items: flex-start;
    }

    &-item {
      width: 45px;
      position: relative;

      @include mq-s {
        width: 22px;
      }

      &:not(:last-child) {
        margin-right: 20px;

        @include mq-s {
          margin-right: 16px;
        }
      }

      &:last-child {
        @include mq-s {
          margin-right: 6px;
        }
      }

      a {
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        position: relative;

        img {
          height: 30px;

          @include mq-s {
            height: 28px;
          }
        }
      }
    }

    &-menu {
      font-size: 8px;
      display: block;
      letter-spacing: -1px;
      font-family: $SUB_FONT_FAMILY;

      @include mq-s {
        display: none;
      }
    }
  }
}

.purchase_count {
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 5px;
  border-radius: 50%;
  background-color: #000;
  color: #FFF;
  line-height: 1em;

  @include mq-s {
    top: 1px;
    right: -6px;
  }
}

.mypage-link {
  position: relative;
  text-align: center;

  img {
    @include mq-s {
      height: 22px;
    }
  }
}

.user-menu {
  position: absolute;
  top: 60px;
  right: -30px;
  display: none;
  width: 200px;
  background-color: $C_GRAY;

  @include mq-s {
    top: 50px;
  }

  &__list {
    flex-wrap: wrap;
  }

  &__name {
    border-bottom: 3px solid #FFF;
    width: 100%;
    padding: 8px 15px;
    font-weight: bold;
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid #FFF;
    padding: 8px 15px;
  }

  &:before {
    content: "";
    background-color: $C_GRAY;
    height: 20px;
    position: absolute;
    top: -10px;
    right: 45px;
    transform: rotate(-45deg);
    width: 20px;

    @include mq-s {
      right: 30px;
    }
  }
}