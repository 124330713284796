/* ========================================

    attention
        注意事項ページでのみ使用するスタイル

    INDEX
        - section

======================================== */
.attention {
  width: 100%;

  &__title {
    display: flex;

    &__text {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    width: 10%;

    img {
      width: 70%;
    }

    @include mq-s {
      width: 20%;
    }
  }

  &__text {
    width: 90%;

    @include mq-s {
      width: 100%;
    }
  }
}