.referrer-page {
  padding: 20px 0;

  & .logo {
    width: 100%;
  }

  & .description {
    margin-top: 30px;
  }
}