/* ========================================

    footer
        フッターのパーツ

    INDEX
        - common

======================================== */

/* ========================================
    common
======================================== */
.footer {
  margin-top: auto;
  background-color: #000;
  color: #FFF;
  text-align: center;

  &__sns {
    padding: 30px 0;

    &--list {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--item {
      display: block;
      width: fit-content;

      &:not(:last-child) {
        margin-right: 25px;
      }

      i {
        font-size: 24px;
      }
    }
  }


  & a {
    color: inherit;
  }


  &__nav {
    padding: 16px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;

    @include mq-s {
      padding: 0;
      border-bottom: none;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-item {
      display: block;
      padding: 0 12.5px;
      line-height: 1em;
      box-sizing: border-box;

      &:not(:last-child) {
        border-right: 1px solid #FFF;
      }

      @include mq-s {
        width: calc(50% - 2px);
        padding: 14px 0;
        border-bottom: 1px solid #FFF;

        &:nth-child(even) {
          border-right: none;
        }
      }

      
      &--inquiry {
        @include mq-pc {
          padding: 8px 12.5px 0;
        }
        @include mq-mb {
          padding: 8px 12.5px 0;
        }
        @include mq-s {
          padding: 14px 0;
        }
      }
    }

    &-list--large &-item {
      width: 100%;
    }
  }

  &__annotation {
    padding: 25px 15px;
    color: inherit;
  }
}

.inquiry {
  text-decoration: underline;
}

.copyright {
  padding-bottom: 42px;

  &__text {
    word-break: break-all;

    .reg_mark {
      // アーティスト本文裏にある商標記号のfont-sizeが、文言2.0remに対して記号1.2remを設定したため、copyright__textのfont-size0.85remに対して同じ比率で設定する
      font-size: 0.51rem;
      vertical-align: top;
    }
  }
}
