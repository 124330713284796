.hidden-content {
  .page-title {
    padding: 0 20px 60px;
    &--msg-title {
      font-family: $MAIN_FONT_FAMILY;
      font-weight: 700;
      display: block;
      line-height: 1.1;

      * {
        font-size: 24px !important;
      }
    }

    &--msg-date {
      font-size: 1.4rem;
      font-weight: 700;
      width: 100%;
    }
  }

  &__description {
    text-align: center;

    &--info {
      margin-top: 15rem;
      font-size: 1.2rem;
    }
  }
}
