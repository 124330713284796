/* ========================================

    category
         categoryページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
$border_icon_color: #707070;
$border_icon: 1px solid $border_icon_color;

.category {
  padding-top: 80px;
  padding-bottom: 80px;
  display: block;

  &.no-top-padding {
    padding-top: 0;
  }

  @include mq-m {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  @include mq-s {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  &-dl {
    width: 100%;
    border: 2px solid $C_BLACK;
    min-height: 60px;
    background-color: $C_WHITE;
    display: flex;
    justify-content: space-between;

    @include mq-s {
      flex-wrap: wrap;
    }

    &__space-bottom2 {
      margin-bottom: 20px;
    }

    &__space-bottom {
      margin-bottom: 16px;
    }

    &__name {
      width: 65%;
      font-size: 20px;
      padding: 5px 20px;
      line-height: 1.2;
      font-weight: 700;
      display: flex;
      align-items: center;

      @include mq-m {
        width: 60%;
        font-size: 1.8rem;
      }
      @include mq-s {
        font-size: 20px;
        width: 100%;
        border-bottom: 1px solid $C_BLACK;
        padding: 5px 15px;
      }

      &__container {
        width: 100%;
      }
    }

    &__price {
      width: 35%;
      display: flex;

      @include mq-m {
        width: 40%;
      }
      @include mq-s {
        width: 100%;
      }

      .price-title {
        background-color: $C_BLACK;
        color: $C_WHITE;
        padding: 0 20px;
        text-align: center;
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      .price-detail {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        p {
          font-family: $ENG_FONT_FAMILY;
          font-size: 2rem;
        }

        .small {
          font-family: $MAIN_FONT_FAMILY;
          font-size: 0.65em;
          display: inline-block;
        }
      }
    }

    &__cart {
      width: 40%;
      padding: 5px 20px;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      line-height: 1.2;
      font-weight: 700;

      @include mq-m {
        font-size: 1.5rem;
      }
      @include mq-s {
        display: none;
      }
    }

    &__count {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px dashed $C_BLACK;
      padding: 5px 10px;

      @include mq-s {
        width: 33.333333%;

        &.first {
          border: none;
        }
      }

      p {
        font-family: $ENG_FONT_FAMILY;
        font-size: 2rem;
        line-height: 1;
      }

      .small {
        font-family: $MAIN_FONT_FAMILY;
        font-size: 0.65em;
        display: inline-block;
      }
    }

    &__link {
      width: 15%;
      display: flex;
      justify-content: center;
      background-color: $C_BLACK;
      text-align: center;
      transition: 0.3s ease;

      @include mq-s {
        display: block;
        width: 100%;
      }

      &:hover {
        opacity: 0.7;
      }

      &.disactive {
        background-color: $C_GRAY2;
        pointer-events: none;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $C_WHITE;
        width: 100%;
        font-weight: 700;

        @include mq-s {
          height: 42px;
        }
      }
    }
  }

  &__list {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include mq-m {
      justify-content: space-evenly;
    }

    &--gacha {
      flex-direction: column;
      align-items: center;

      &--cart {
        width: 100%;
        max-width: 800px;
        background-color: white;
        padding: 16px;
      }
    }
  }

  &__description {
    font-size: 14px;
  }

  &-product {
    &__item {
      display: flex;
      flex-direction: column;
      width: 180px;
      box-sizing: content-box;
      margin-top: 30px;
      padding: 0 10px;
      @include mq-s {
        padding: 0 5px;
        width: calc((100% - 20px) / 2);
      }
    }

    &__thumb {
      width: 180px;
      padding-top: calc(1 / 1.4 * 100%);
      position: relative;
      overflow: hidden;
      background-color: #fff;

      &.gacha-thumb {
        display: none;
      }

      @include loadingBb();

      @include mq-s {
        width: 100%;

        &.gacha-thumb {
          display: block;
        }
      }

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
        //position: absolute;
        //top: 0;
        //left: 0;
        z-index: 10;
        width: auto;
        height: 100%;
        max-width: none;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    &__content-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex: 1;
    }

    &__button {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: $C_WHITE;
      font-weight: bold;
      color: $C_BLACK;
      width: calc(100% - 45px);
      font-size: 1.4rem;


      @include mq-s {
        font-size: 1.2rem;
      }

      @include mq-iphone5 {
        font-size: 1rem;
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }

      &--cart {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: $C_WHITE;
        font-weight: bold;
        color: $C_BLACK;
        width: 40px;
        margin-right: 5px;
        background-image: url("../images/icon_shopping.svg");
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: center;

        &.active {
          background-image: url("../images/icon_shopping_white.svg");
          background-color: $C_RED;
        }

        @include mq-iphone5 {
          width: 36px;
          height: 36px;
          background-size: 20px;
          background-position: 7px;
        }
      }
    }
  }

  &-goods {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    @include mq-s {
      flex-wrap: wrap;
    }

    &__stock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 70%;

      @include mq-s {
        width: 100%;
        margin-bottom: 10px;

        div {
          flex: 1;

          input {
            width: 100px;
          }
        }
      }
    }

    &__cart {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30%;

      @include mq-s {
        width: 100%;
      }

      .btn-100 {
        margin: 0 auto;
      }
    }
  }

  // random banner
  &-random-banner {
    &_wrapper {
      padding: 32px 0 64px;
    }
  }

  &-spec {
    font-size: 14px;
  }
  &-comment {
    font-size: 14px;
  }

  &-main__news {
    .content__inner {
      width: 100%;
      .information {
        &__title--date  {
          // @include keep-font-size(15, 15, 0, 117.75)
        }
        &__subtitle {
          // @include keep-font-size(14, 13, 0, 117.75)
        }
        &__detail {
          // @include keep-font-size(15, 14, 0, 42)
        }
      }
    }
  }

  &__carousel {

    .slick-track {
      //display: flex !important;
    }
    .slick-slide {
      //height: inherit !important;
    }

    .slick-list {
      padding: 12px 0 !important;
      .slick-slide {
        text-align: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__image-wrapper {
      padding: 0 10px;
      width: 200px;
      max-width: 200px;
      height: calc(180px * 0.713311);

      @include mq-s {
        padding: 0 5px;
        max-width: initial;
        width: calc((100vw - 20px - 20px) / 2);
        height: calc(((100vw - 20px - 20px) / 2) * 0.713311);
      }
    }

    &__image {
      object-fit: contain;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.20);
    }
  }

  &-pre-cart-actions {
    .pre-cart-wrapper {
      margin: 16px auto;
    }
  }
}

.purchase {
  &__cart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq-s {
      font-size: 1rem;

      &--error,
      &--note {
        margin-top: 5px;
      }
    }

    @include mq-iphone5 {
      padding: 0.9rem;
    }

    &--center {
      justify-content: center;
    }

    &--error,
    &--note {
      font-size: 1rem;
    }
  }

  &__cart-image {
    height: 50px;
  }

  &__cart-qty {
    display: flex;
    justify-content: center;
  }

  &__cart-box {
    border: $border_icon;
    font-size: 1rem;
    margin: 2rem 1rem;
    padding: 0 10px;

    &--bg-white {
      background-color: #ffffff;
    }

    &--flex {
      display: flex;
      justify-content: center;

      @include mq-s {
        padding: 0;
        display: flex;
        flex-direction: column;
        font-size: 10px;
        width: 100%;
        height: 50px;
        align-items: center;
      }
      @include mq-iphone5 {
        height: 46px;
        font-size: 8px;
      }
    }

    @include mq-s {
      padding: 0 10px;
    }

    @include mq-iphone5 {
      padding: 0 8px;
      margin: 1rem .5rem;
    }
  }

  &__cart-title {
    padding-right: 15px;
    white-space: nowrap;

    @include mq-s {
      padding-right: 0;
    }
  }

  &__number {
    width: 80px;
    height: 50px;
    padding: 0 10px;
    border-color: $border_icon_color !important;

    @include mq-s {
      padding: 0 5px;
      font-size: 14px;
      max-width: 50px;
    }

    @include mq-iphone5 {
      max-width: 46px;
      width: auto;
      padding: 8px;
    }
  }

  &__number__select__wrapper {
    position: relative;
    display: inline-block;

    &::before {
      border-bottom: 4px solid $C_BLACK;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: "";
      position: absolute;
      right: 4px;
      top: 10px;
      width: 0;
    }

    &::after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $C_BLACK;
      content: "";
      position: absolute;
      right: 4px;
      bottom: 10px;
      width: 0;
    }
  }

  &__number__select {
    width: 52px;
    max-width: 52px;
    height: 40px;
    padding: 0 10px 0 6px;
    color: $C_BLACK;
    background-color: $C_WHITE;
    border-width: 2px;
    border-color: $border_icon_color !important;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__photo {
    width: 100%;
  }

  &__cart-icon {
    padding: 0;
    display: flex;
    align-items: center;
    height: 50px;

    &:not(:last-child) {
      padding-right: 15px;
    }

    &--button {
      width: 30px;
      height: 30px;
      background-size: 17px;

      display: inline-block;
      text-align: center;
      background-color: $C_WHITE;
      font-weight: bold;
      color: $C_BLACK;
      background-image: url("../images/icon_shopping.svg");
      background-repeat: no-repeat;
      background-position: 5px;
      margin-right: 8px;
      border: $border_icon !important;

      &.active {
        background-image: url("../images/icon_shopping_white.svg");
        background-color: $C_RED;
      }
    }

    @include mq-s {
      height: 100%;
      width: 100%;

      &:not(:last-child) {
        padding: 0;
      }

      &--nosp {
        display: none;
      }

      &--button {
        background-size: 10px;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background-position: 2px;
      }
    }

    @include mq-iphone5 {

      &--button {
        background-size: 8px;
        width: 14px;
        height: 14px;
        margin-right: 3px;
        background-position: 1px 2px;
      }
    }
  }

  &__button-add-to-cart {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    background-color: $C_WHITE;
    border: $border_icon !important;
    font-weight: bold;
    color: $C_BLACK;

    background-image: url("../images/icon_shopping.svg");
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 9px;

    &.active {
      background-image: url("../images/icon_shopping_white.svg");
      background-color: $C_RED;
    }

    @include mq-s {
    }

    @include mq-iphone5 {
      background-size: 28px;
      background-position: 9px;
    }

    &__x_sm {
      width: 168px;
      height: 40px;
      line-height: 38px;
      color: $C_BLACK;
      text-align: left;
      padding: 0 12px;
      background-size: 22px;
      background-position: 132px;

      &.active {
        color: $C_WHITE;
      }
    }
  }
}

.cart-info {
  margin-bottom: 2rem;

  @include mq-s {
    margin-bottom: 0;
  }

  &__image {
    max-width: 94%;
  }

  & .purchase__cart-box {
    margin: 0 0;
    font-size: 1rem;

    &--flex {
      flex-direction: row;


      @include mq-s {
        font-size: 10px;
      }

      @include mq-iphone5 {
        font-size: 8px;
      }

    }
  }

  & .purchase__cart-icon {
    width: auto;

    &:not(:last-child) {
      margin-right: 10px;
    }

    @include mq-iphone5 {
      &:not(:last-child) {
        margin-right: 6px;
      }
    }

    &--button {
      width: 28px;
      height: 28px;
      background-size: 16px;
      background-position: 3px;
    }
  }
}

.set-sell {
  &__annotation {
    display: flex;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid $C_BLACK;
    border-bottom: 2px solid $C_BLACK;
    align-items: center;
    justify-content: center;
    @include mq-s {
      display: inline-block;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 2px solid $C_BLACK;
      border-bottom: 2px solid $C_BLACK;
    }
  }
}

#popup-purchase {
  max-width: 620px;
  margin: auto;
  @include mq-s {
    width: 100%;
  }
}

.popup-purchase {

  &__description__slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--content {
      width: 70vw;
    }
  }

  &__image {
    width: 100%;
    height: calc(620px * 0.713412);
    overflow: hidden;

    @media screen and (max-width: 767.98px){
      height: calc((100vw - 180px) * 0.713412) !important;
    }

    @media screen and (max-width: 576.98px) {
      height: calc((100vw - 60px) * 0.713412) !important;
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }

  }

  &__caption {
    min-height: 35px;
    justify-content: center;
  }
}

.spec_slider {

  &_container {
    margin-top: 8px;
    height: 200px;
    display: grid;
    gap: 0 12px;
    grid-template-columns: 20px calc(100% - 64px) 20px;
  }

  &_description {
    font-size: 13px;
    color: $C_RED;
    text-align: center;
    margin: 12px 0 0 0;

    @include mq-s {
      font-size: 10px !important;
    }
  }

  &_arrow_left, &_arrow_right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &_carousel {
    height: 100%;

    &_image {
      height: 200px;
      object-fit: contain;
    }
  }

}

.sub_commodity_name {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
}
//.slider {
//  margin: 0 auto;
//  width: 100%;
//
//  .slider-item {
//    margin: 0 auto;
//    padding: 0 20px;
//
//    img {
//      margin: 0 auto;
//    }
//  }
//}

.pointer {
  cursor: pointer;
}
