$pageItem_bg: #fff;
$pageItem_color: #232323;

.pagination {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  border-radius: 0;
  justify-content: center;
}

.page-item {
  .page-link {
    color: $pageItem_color;
    background-color: $pageItem_bg;
    padding: 0.5rem .9rem;
    margin: 0 0.3125rem;
    display: inline-block;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border-radius: 0 !important;
    border-color: transparent !important;
    box-shadow: none !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      background-color: $pageItem_color;
      color: $pageItem_bg;
      border-color: transparent;
    }

    &.active {
      background-color: $pageItem_color;
      color: $pageItem_bg;
      border-color: $pageItem_bg;
    }

    &.disabled {
      background-color: $pageItem_bg;
      color: rgba($color: $pageItem_color, $alpha: .6);
      opacity: 1;
      cursor: default;
    }
  }

}
