
.order-item {
  height: 100%;

  th {
    padding: 0;
    width: 32px;
    text-align: center;
  }

  &__name {
    font-weight: 700;
  }

  &__id {
    color: $C_GRAY2;
  }

  &__quantity {
    width: 140px;
  }

  &__detail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      padding-right: 15px;
      display: inline-block;
    }
  }

  &__thumb {
    max-width: 120px;

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      row-gap: 10px;
      flex-direction: column;
      margin-right: 20px;

      @include mq-s {
        margin-bottom: 20px;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__main {
    flex: 1;
    min-width: 100px;
    @media screen and (max-width:400px) {
      flex: unset;
      width: 100%;
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 340px;
    width: 85%;
    margin: 20px 0 0 auto;
    letter-spacing: 0.03em;

    dt {
      padding-right: 10px;
      width: 50%;
    }

    dd {
      width: 48%;
      text-align: right;
      font-weight: 700;
    }
  }
}

.no-word-break {
  word-break: keep-all;
}


.guide-item {
  th {
    width: 40px;
    box-sizing: content-box;
    text-align: center;

    @include mq-s() {
      width: 10px;
    }
  }
}

.div-scroll {
  width: 100%;
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 20px;
  border: 1px solid $C_GRAY2;

  @include mq-s() {
    height: 70vh;
    padding: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.order-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.order-item_wrapper {
  width: 55%;

  @include mq-mb {
    width: 100%;
  }
}

.order-menu_wrapper {
  width: 45%;

  @include mq-mb {
    width: 100%;
  }
}

.order-menu {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    padding: 10px;
    text-align: left;
    background: $C_BLACK;
    border: solid $C_GRAY2;
    border-width: 1px 1px 1px 0px;
    color: $C_WHITE;

    @include mq-mb {
      border-width: 1px 1px 1px 1px;
    }
  }

  td {
    padding: 10px;
    text-align: left;
    background: $C_WHITE;
    border: solid $C_GRAY2;
    border-width: 1px 1px 1px 0px;

    @include mq-mb {
      border-width: 1px 1px 1px 1px;
    }
  }

  .estimated_delivery__header {
    width: 160px;
    @include mq-mb {
      width: 92px;
    }
  }

  .shipping_method_selection_charge__header {
    @include mq-mb {
      padding: 10px 6px;
    }
  }

  .nb_days_of_order_until_shipping__br {
    display: none;

    @include mq-mb() {
      display: block;
    }
  }

  .shipping_description {
    font-size: 10px;

    &__paragraph {
      @include mq-s() {
        padding-left: 10px;
      }
    }
  }

  .delivery_time_select {
    white-space: break-spaces;

    &__wrapper {
      width: 100%;
    }

    @include mq-s() {
      font-size: 12px;
    }
  }
}

.landing__main--text {
  //@include keep-font-size(14, 14, 0, 20)
}

.static__main--text {
  // @include keep-font-size(14, 14, 0, 20)
}
