/* ========================================

    event
        eventページのみで使うスタイル

    INDEX
        - main

======================================== */

.event-top {
  margin-top: 20px;

  // @include mq-m {
  //     margin-top: 385px;
  // }

  // @include mq-s {
  //     margin-top: 115px;
  // }
}

.event-main {
  position: relative;
  // padding-top: 32%;
  padding-bottom: 50px;

  // @media screen and (min-width: 1001px) {
  //     padding-top: 380px;
  // }
  // @include mq-m {
  //     padding-top: 30%;
  //     padding-bottom: 30px;
  // }
  // @include mq-s {
  //     padding-top: 65%;
  //     padding-bottom: 20px;
  // }
  // @media screen and (max-width: 470px) {
  //     padding-top: 55%;
  // }

  &__photo {
    width: 100%;
    // padding-top: 75%;
    // position: absolute;
    // top: -400px;
    left: 0;
    margin-bottom: 30px;

    @include loadingBb($LOADING_ICON_MAIN_IMAGE_SIZE);

    img {
      // position: absolute;
      // top: 0;
      height: auto;
      width: 100%;
      // object-fit: cover;
    }

    @include mq-m {
      // top: -365px;
    }

    @include mq-s {
      // top: -95px;
    }
  }

  &__name {
    //@include keep-font-size(30, 20, 0, 20);
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 1.2em;
    //
    //@include mq-m {
    //  font-size: 2.8rem;
    //}
  }

  &__detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;
    align-items: flex-start;
  }

  &__news {
    .content__inner {
      width: 100%;
      .information {
        &__title--date  {
          // @include keep-font-size(15, 15, 0, 117.75)
        }
        &__subtitle {
          // @include keep-font-size(14, 13, 0, 117.75)
        }
        &__detail {
          // @include keep-font-size(15, 14, 0, 42)
        }
      }
    }
  }

  &__label {
    display: flex;
    flex-wrap: wrap;
    width: 50%;

    &.right {
      justify-content: flex-start;
    }

    &.left {
      justify-content: flex-end;
    }

    span {
      display: inline-block;
      min-width: 125px;
      padding: 5px 0px;
      font-weight: bold;
      line-height: 1em;
      text-align: center;
    }

    &--period {
      background-color: #000;
      color: #FFF;
      border: 1px solid #000;
      font-size: 1.5rem;
      margin-right: 10px;
    }

    &--date {
      font-size: 1.7rem;
      color: #8f939a;
      @include mq-s {
        text-align: left !important;
        font-size: 1.4rem;
      }
    }

    &--new {
      color: $C_RED;
      font-family: $ENG_FONT_FAMILY;
      background-color: #FFF;
      border: 1px solid $C_RED;
      margin-left: 10px;
      font-size: 1.5rem;
    }

    &--3day {
      color: $C_RED;
      background-color: rgba(185, 0, 0, 0.3);
      border: 1px solid $C_RED;
      margin-left: 10px;
      font-size: 1.5rem;
    }

    &--today {
      color: #FFF;
      background-color: $C_RED;
      border: 1px solid $C_RED;
      margin-left: 10px;
      font-size: 1.5rem;
    }
  }

  &__organizer {
    padding: 20px 0;
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.tab {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;

  &__switch {
    display: inline-block;
    width: 50%;
    text-align: center;
    padding: 12px 0;
  }

  &__title-en {
    display: block;
    font-size: 2.4rem;

    @include mq-s {
      font-size: 2rem;
    }
  }

  &__title-jp {
    display: block;
    font-size: 1.5rem;
    line-height: 1;

    @include mq-s {
      font-size: 1.3rem;
      line-height: 1;
    }
  }

  input[type="radio"] {
    display: none;
  }
}

#tab-1:checked ~ .tab-1_label {
  background-color: $C_GRAY;
}

#tab-2:checked ~ .tab-2_label {
  background-color: $C_GRAY;
}


.event-category {
  padding-top: 80px;
  padding-bottom: 80px;
  display: none;

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    &,
    &:visited,
    &:focus {
      cursor: pointer;
    }

    &:hover {
      &--button {
        background-color: #8F939A;
      }
    }
  }

  &.active {
    display: block;
  }

  @include mq-m {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  @include mq-s {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  &__switch {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    gap: 2px;

    @include mq-s {
      gap: 0;
      justify-content: center;
    }

    &-item {
      background-color: $C_WHITE;
      width: 19.4%;
      padding: 12px 5px;
      font-family: $ENG_FONT_FAMILY;
      font-weight: 700;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq-s {
        width: 48%;
        padding: 12px 5px;
        margin: 0 1% 5px;
        font-size: 1.25rem;
      }
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + .event-category__switch-item {
      background-color: #000;
      color: #FFF;
    }
  }

  &__list {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include mq-m {
      justify-content: space-between;
    }

  }

  &__list &__item {
    width: 33.33333%;
    padding: 0 20px;
    margin-bottom: 60px;
  }

  &__item {
    position: relative;

    @include mq-m {
      width: 48%;
      padding: 0;
    }
    @include mq-s {
      width: 100% !important;
      padding: 0 !important;
      margin-bottom: 50px !important;
    }

    &--main {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 16px;
      border-bottom: 1px solid #000;

      @include mq-s {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &--price {
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .price-no {
        margin-right: 10px;
      }

      .price-item {
        font-size: 2rem;
        line-height: 1;

        .tax {
          font-size: 1.4rem;
          display: inline-block;
        }
      }
    }

    &-button {
      width: 100%;
      display: inline-block;
      text-align: center;
      height: 40px;
      line-height: 40px;
      background-color: #FFF;
      font-weight: bold;
      color: #000;

      &:hover {
        background-color: #8F939A;
      }
    }
  }

  &__recommend {
    position: absolute;
    left: 5px;
    top: -15px;
    z-index: 11;
    width: 50px;

    @include mq-m {
      left: -10px;
      top: -15px;
    }
    @include mq-s {
      width: 40px;
      left: -10px;
      top: -12px;
    }
  }
}

.cate-link {
  &__thumb {
    width: 293px;
    height: 209px;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
    background-color: #fff;

    @include loadingBb($LOADING_ICON_EVENT_SIZE);
    @include mq-s {
      padding: 0;
      width: calc((100vw - 20px - 20px) / 2); //400px時に180
      height: calc(((100vw - 20px - 20px) / 2) * 0.713311); //400px時に
    }

    img {
      //width: 100%;
      //height: 100%;
      //background-color: #fff;
      //object-fit: contain;
      //position: absolute;
      //top: 0;
      //left: 0;
      z-index: 10;
      width: auto;
      height: 100%;
      max-width: none;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  &__blur {
    width: 100%;
    height: 100%;
    filter: blur(7px);
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
  }

  &__detail{
    flex: 1;
    @include mq-s {
      flex: auto;
      padding-left: 2%;
      width: 48%;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;

    @include mq-s {
      font-size: 16px;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.5;

    @include mq-s {
      font-size: 1.2rem;
      line-height: 1.5;
    }
  }
}


.sub-event-category {
  &__list {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include mq-s {
      justify-content: space-between;
    }
  }

  &__item {
    width: 25%;
    padding: 0 10px;
    margin-bottom: 40px;
    position: relative;

    @include mq-m {
      width: 33.33333%;
    }
    @include mq-s {
      width: 48%;
      padding: 0;
    }

    &-main {
      width: 100%;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #000;
    }

    &--price {
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .price-no {
        margin-right: 10px;
      }

      .price-item {
        font-size: 1.6rem;
        line-height: 1;

        .tax {
          font-size: 1.2rem;
          display: inline-block;
        }
      }
    }

    &-button {
      width: 100%;
      display: inline-block;
      text-align: center;
      height: 40px;
      line-height: 40px;
      background-color: #FFF;
      font-weight: bold;
      color: #000;

      &:hover {
        background-color: #8F939A;
      }
    }
  }

  &__recommend {
    position: absolute;
    left: 0;
    top: -15px;
    z-index: 10;
    width: 50px;

    @include mq-m {
      left: -10px;
      top: -15px;
    }
    @include mq-s {
      width: 40px;
      left: -10px;
      top: -12px;
    }
  }
}

.sub-cate-link {
  &__thumb {
    width: 100%;
    padding-top: 75%;
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  &__blur {
    width: 100%;
    height: 100%;
    filter: blur(7px);
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
  }

  &__title {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: bold;

    @include mq-s {
      font-size: 1.3rem;
      line-height: 1.5;
    }
  }
}

.ldh-banner-image {
  img {
    width: 100%;
  }
  margin: 0;
}
.event-main__no-margin {
  margin-bottom: 10px !important;
}
