.registration--pre {
  &__description {
    font-size: 14px;
  }
}

.registration--from {
  &__birthday-list {
    align-items: start;

    select {
      @include mq-s {
        padding: 10px 28px 10px 10px;
      }
    }
  }
  &__birthday-input {
    display: flex;
    align-items: center;
  }

  &__password {
    &__input {
      height: 40px;
    }

    .form-password__append {
      height: 40px;
      border-color: $C_BLACK;
    }
  }

  @include mq-s {
    &__year {
      min-width: 100px;
    }
    &__month {
      min-width: 80px;
    }
    &__day {
      min-width: 80px;
    }
  }
}
