/* ========================================

    nav
        ナビゲーションのパーツ

    INDEX
        - global
        - trigger
        - sub
        - pnkz
        - members menu

======================================== */

/* ========================================
    global
======================================== */
.global-menu {

}


/* ========================================
    trigger
======================================== */
// 以下から持ってきたハンバーガーメニュー
// https://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html
%trigger-base {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.trigger-menu {
  @extend %trigger-base;
  $width_size: 45px;
  $height_size: 40px;
  $border_size: 2px;
  $padding_size: 10px;
  $middle_position: #{calc($height_size / 2) - calc($border_size / 2) - ($padding_size)};
  width: $width_size;
  height: $height_size;
  padding: $padding_size;
  position: fixed;
  top: 0;
  left: 0;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  span {
    @extend %trigger-base;
    position: absolute;
    left: 0;
    width: 100%;
    height: $border_size;
    background-color: black;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: $middle_position;
    }

    &:nth-of-type(3) {
      bottom: 0;
    }
  }

  &.js-active {
    span {
      &:nth-of-type(1) {
        transform: translateY(#{$middle_position}) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-#{$middle_position}) rotate(45deg);
      }
    }
  }
}


/* ========================================
    sub
======================================== */
.sub-menu {

}


/* ========================================
    pnkz
======================================== */
.pnkz {

}

/* ========================================
    members menu
======================================== */
.members-menu {

}