/* ========================================

    login
        loginページのみで使うスタイル

    INDEX
        - form

======================================== */
.login-dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  max-width: 900px;
  margin: 0 auto;

  @include mq-s {
    width: 90%;
  }

  dt {
    width: 20%;
    text-align: center;
    color: #FFF;
    background-color: $C_BLACK;
    line-height: 40px;
    height: 40px;
    font-size: 1.6rem;

    @include mq-s {
      width: 100%;
      margin-bottom: 5px;
    }

    &.blank {
      @include mq-s {
        display: none;
      }
      background-color: transparent;
      height: 0;
    }
  }

  dd {
    width: 78%;

    @include mq-s {
      width: 100%;
    }
  }


  &__input {
    border: 1px solid $C_BLACK;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    background: $C_WHITE;

    &--reset-pw-email {
      @include mq-s {
        font-size: 14px;
        padding: 0 8px;
      }
    }
  }

  .form-password__append {
    height: 40px;
    border-color: $C_BLACK;
  }

  &__select {
    position: relative;
    width: 100%;
    border: 1px solid $C_BLACK;
    background: $C_WHITE;

    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      color: $C_BLACK;
      font-weight: 900;
      display: block;
      position: absolute;
      right: 10px;
      top: 23%;
      font-size: 1.5rem;
      transition: 0.3s ease;
      pointer-events: none;
    }

    select {
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
      display: block;
      padding: 0 45px 0 20px;
      width: 100%;
      background: transparent;
      box-shadow: none;
      border: 0;
      line-height: 40px;
      height: 40px;

      @include mq-s {
        padding: 0 45px 0 10px;
      }

      &::-ms-expand {
        display: none;
      }

      &:invalid {
        color: $C_GRAY2;
      }
    }

    &--reset-pw-year {
      select {
        @include mq-s {
          padding: 0 25px 0 10px;
          min-width: 71px;
        }
      }
    }

    &--reset-pw-month,&--reset-pw-day {
      select {
        @include mq-s {
          padding: 0 25px 0 10px;
          min-width: 53px;
        }
      }
    }
  }

  .reset-pw-birthday>span {
    @include mq-s {
      flex: 1;

      &:last-child {
        flex: 0.92;
      }
    }
  }
}
