.modal {

  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    overflow-y: scroll;
    transition: opacity 0.3s ease;
  }

  &-wrapper {
    //display: table-cell;
    //vertical-align: middle;
    padding: 20px;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: 100%;
    @include mq-s {
      padding: 0;
    }
  }

  &-container {
    align-self: flex-start;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;

    @include mq-s {
      max-height: calc((var(--vh) * 100) - 40px);
    }
  }

  &-close {
    cursor: pointer;
    margin: 0px auto;
    color: #fff;
    text-align: right;
  }

  &-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  &-body {
    margin: 20px 0;
  }

  &-default-button {
    float: right;
  }

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  @include mq-mb {
    &-container {
      padding: 12px;
      width: 90% !important;
      overflow-y: scroll;
    }
    &-close {
      width: 90% !important;
      margin-bottom: 32px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    .footer {
      display: flex;
      justify-content: center;

      &__close {
        border: 2px solid black;
        cursor: pointer;
        padding: 1rem 3rem;
        background-color: transparent;
      }

      &__ok {
        cursor: pointer;
        padding: 1rem 3rem;
      }
    }
  }

}

