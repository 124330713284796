/* ========================================

    pager
        ページャーのパーツ

    INDEX
        - pager

======================================== */

/* ========================================
    pager
======================================== */
// ========================================
//     pager
//         ページャーのパーツ
// ========================================
%pager-btn {
  font-family: $ENG_FONT_FAMILY;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  color: $C_BLACK;
  margin: 4px;
  width: 40px;
  height: 40px;
  background: $C_WHITE;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;

  @include mq-s() {
    width: 30px;
    height: 30px;
  }

  &:hover {
    background: $C_GRAY2;
  }

  &.active {
    background: $C_BLACK;
    color: $C_WHITE;
    pointer-events: none;
  }

  &.none {
    pointer-events: none;
    opacity: 0.5;
  }
}


.pager {
  display: flex;
  justify-content: center;

  .btn-back {
    @extend %pager-btn;
    margin-right: 12px;
  }

  .btn-next {
    @extend %pager-btn;
    margin-left: 12px;
  }

  .btn-num {
    @extend %pager-btn;
  }
}
