@charset "UTF-8";
// ========================================
//  aem scss templates
//      ver 2.0.5
// ========================================

@import '~@fortawesome/fontawesome-free/css/all.min.css';

/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
@import "common/reset";
@import "common/base";
@import "common/parts";

/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
@import "components/common";
@import "components/header";
@import "components/footer";
@import "components/sns";
@import "components/nav";
@import "components/pagination";
@import "components/support";
@import "components/pager";
@import "components/title";
@import "components/btn";
@import "components/list";
@import "components/form";
@import "components/lity";
@import "components/modal";

/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
@import "pages/index";
@import "pages/event";
@import "pages/category";
@import "pages/cart";
@import "pages/login";
@import "pages/attention";
@import "pages/other";
@import "pages/arrange";
@import "pages/purchase";
@import "pages/referrer";
@import "pages/hidden-content";
@import "pages/history-page";
@import "pages/member";
@import "pages/mypage";
@import "pages/inquiry";

@import "plugins/slick-theme";
@import "plugins/slick";
