.inquiry__form {
    @include mq-s {
        input {
            padding: 10px 5px;
        }

        &--email, &--email-confirm {
            font-size: 15px;
        }
    }
}