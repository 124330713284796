/* ========================================
    
    parts
        付けるだけで動くスタイルのパーツ

    INDEX
        - element
        - layout
        - font
        - color
        - margin, padding
        - border

======================================== */

/* ========================================
    element
======================================== */
// XLサイズ以外で非表示
.xl-elem {
  @include mq-xl(false) {
    display: none !important;
  }

  &--inline {
    display: inline;

    @include mq-xl(false) {
      display: none !important;
    }
  }
}

// Lサイズ以外で非表示
.l-elem {
  @include mq-l(false) {
    display: none !important;
  }

  &--inline {
    display: inline;

    @include mq-l(false) {
      display: none !important;
    }
  }
}

// Mサイズ以外で非表示
.m-elem {
  @include mq-m(false) {
    display: none !important;
  }

  &--inline {
    display: inline;

    @include mq-m(false) {
      display: none !important;
    }
  }
}

// Sサイズ以外で非表示
.s-elem {
  @include mq-s(false) {
    display: none !important;
  }

  &--inline {
    display: inline;

    @include mq-s(false) {
      display: none !important;
    }
  }
}

// モバイル、タブレットサイズ以外で非表示
.mb-elem {
  @include mq-mb(false) {
    display: none !important;
  }

  &--inline {
    display: inline;

    @include mq-mb(false) {
      display: none !important;
    }
  }
}

// PCサイズ以外で非表示
.pc-elem {
  @include mq-pc(false) {
    display: none !important;
  }

  &--inline {
    display: inline;

    @include mq-pc(false) {
      display: none !important;
    }
  }
}


/* ========================================
    layout
======================================== */
.al-c {
  text-align: center !important;
}

.al-r {
  text-align: right !important;
}

.al-l {
  text-align: left !important;
}

.fr {
  float: right !important;
}

.fl {
  float: left !important;
}

.cf {
  @include clearfix();
}


/* ========================================
    font
======================================== */
%x-small {
  font-size: 0.7em;
  line-height: 1.6;
}

.x-small {
  @extend %x-small;
}

%small {
  font-size: 0.85em;
  line-height: 1.6;
}

.small {
  @extend %small;
}

%large {
  font-size: 1.2em;
  line-height: 1.6;
}

.large {
  @extend %large;
}

%x-large {
  font-size: 1.5em;
  line-height: 1.6;
}

.x-large {
  @extend %x-large;
}

%bold {
  font-weight: bold;
}

.bold {
  @extend %bold;
}

%italic {
  font-style: italic;
}

.italic {
  @extend %italic;
}

%link {
  color: $C_LINK;
  cursor: pointer;
}

.link {
  @extend %link;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-weight: 900;
    color: $C_BLACK;
  }
}

.link-line {
  @extend %link;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


/* ========================================
    color
======================================== */
.c-white {
  color: $C_WHITE !important;
}

.c-gray {
  color: $C_GRAY !important;
}

.c-darkgray {
  color: $C_GRAY2;
}

.c-black {
  color: $C_BLACK !important;
}

.c-red {
  color: $C_RED !important;
}

.c-green {
  color: $C_GREEN !important;
}

.c-blue {
  color: $C_BLUE !important;
}

.c-yellow {
  color: $C_YELLOW !important;
}

.c-pink {
  color: $C_PINK !important;
}

.c-purple {
  color: $C_PURPLE !important;
}


/* ========================================
    margin, padding
======================================== */
// 0〜120まで5px刻み
@for $i from 0 through 24 {
  $tmp: $i*5;

  // margin
  .mt-#{$tmp} {
    margin-top: #{$tmp}px !important;
  }

  .mr-#{$tmp} {
    margin-right: #{$tmp}px !important;
  }

  .mb-#{$tmp} {
    margin-bottom: #{$tmp}px !important;
  }

  .ml-#{$tmp} {
    margin-left: #{$tmp}px !important;
  }

  // padding
  .pt-#{$tmp} {
    padding-top: #{$tmp}px !important;
  }

  .pr-#{$tmp} {
    padding-right: #{$tmp}px !important;
  }

  .pb-#{$tmp} {
    padding-bottom: #{$tmp}px !important;
  }

  .pl-#{$tmp} {
    padding-left: #{$tmp}px !important;
  }
}


/* ========================================
    border
======================================== */
hr {
  &.solid {
    border: none;
    border-top: 1px solid $C_BLACK;
  }

  &.dot {
    border: none;
    border-top: 1px dotted $C_BLACK;
  }
}

/* ========================================
    a, button
======================================== */
.button {
  display: inline-block;
  margin: 0 auto;
  padding: 30px;
  min-width: 300px;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  box-shadow: #00000033 0 0 10px;

  &-wrapper {
    display: flex;
    justify-content: center;
  }

  &--red {
    background-color: $C_RED;
    border: 2px solid #000;
    color: #FFF;

    & {
      background-color: #da8989;
    }
  }

  &--white {
    background-color: #FFF;
    border: 2px solid #FFF;
    color: #000;

    &:hover {
      background-color: #8F939A;
    }
  }

  &--100 {
    width: 100%;
  }
}

/* ========================================
    breadclumbList
======================================== */
.bread-clumb {
  padding-top: 50px;

  @include mq-mb {
    padding-top: 30px;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    display: inline;

    &:not(:last-child)::after {
      font-family: "Font Awesome 5 Free";
      content: "\f0da";
      font-weight: 900;
      color: #000;
      margin: 0 5px;
    }
  }

  &__link {
    font-size: 1.2rem;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}

.w-max-50 {
  min-width: 50px !important;
}

.w-min-80 {
  min-width: 80px !important;
}

.w-min-90 {
  min-width: 90px !important;
}

.w-min-120 {
  min-width: 120px !important;
}