/* ========================================

    form
        フォーム系のスタイル

    INDEX
        - select

======================================== */

/* ========================================
    select
======================================== */
%form-text {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;

  border: 1px solid $C_BLACK;
  border-left: 4px solid $C_BLACK;
  padding: 10px;
  background: $C_WHITE;
  margin: 2px 0;

  &.error {
    border-left: 4px solid $C_RED;
  }
}

.form-text {
  @extend %form-text;

  &--100 {
    @extend %form-text;
    width: 100%;
  }

  &--80 {
    @extend %form-text;
    width: 80%;

    @include mq-s() {
      width: 100%;
    }
  }

  &--50 {
    @extend %form-text;
    width: 45%;
    margin-right: 1%;

    @include mq-s() {
      width: 100%;
    }
  }

  &--30 {
    @extend %form-text;
    width: 30%;
    margin-right: 1%;
  }
}

textarea.form-text--100 {
  height: 200px;
}


.form-select {
  position: relative;
  width: 48%;
  margin: 2px 1% 2px 0;
  border: 1px solid $C_BLACK;
  border-left: 4px solid $C_BLACK;
  background: $C_WHITE;

  @include mq-s() {
    width: 100%;
    margin: 2px 0;
  }

  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    color: $C_BLACK;
    font-weight: 900;
    display: block;
    position: absolute;
    right: 10px;
    top: 23%;
    font-size: 1.5rem;
    transition: 0.3s ease;
    pointer-events: none;
  }

  select {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    display: block;
    padding: 10px 40px 10px 10px;
    width: 100%;
    background: transparent;
    box-shadow: none;
    border: 0;
    line-height: 1.75 !important;
    color: $C_BLACK;

    &::-ms-expand {
      display: none;
    }

    &:invalid {
      color: $C_GRAY2;
    }
  }

  option {
    padding: 10px 40px 10px 10px;
  }
}


input[type=checkbox],
input[type=radio] {
  display: none;
}

.check {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0 auto;

  &--term {
    padding: 10px;
    background-color: #f0f2f4;
  }

  &--dummy {
    position: absolute;
    top: 3.5px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $C_BLACK;
    background: $C_WHITE;

    &__disabled {
      border: 1px solid #BDBDBD;
      background: #F1F1F1;
    }
  }

  &--text {
    padding-left: 26px;
    display: inline-block;

    &__disabled {
      color: #BDBDBD;
    }
  }
}

input[type=checkbox]:checked + .check--dummy::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hlY2siPjxwb2x5bGluZSBwb2ludHM9IjIwIDYgOSAxNyA0IDEyIj48L3BvbHlsaW5lPjwvc3ZnPg==") no-repeat center;
  background-size: contain;
}

.radio--label {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding-left: 26px;
  position: relative;
  width: auto;

  &::before {
    content: '';
    background: $C_WHITE;
    border-radius: 50%;
    border: 1px solid $C_BLACK;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    left: 0;

  }

  &::after {
    content: '';
    background: $C_BLACK;
    border-radius: 50%;
    display: block;
    width: 14px;
    height: 14px;
    margin-top: -6px;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 4px;

  }
}

input[type=radio]:checked + .radio--label::after {
  opacity: 1;
}


.form-btn {
  display: inline-block;
  cursor: pointer;
  background: $C_BLACK;
  color: $C_WHITE;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 15px;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1;
  margin-left: 10px;

  @include mq-s() {
    margin-left: 0;
    margin-top: 10px;
  }
}

.edit-address-caution {
  padding-left: 10px;
  @include mq-s() {
    padding-left: 0;
    display: block;
  }
}

.validate-error {
  margin-bottom: 16px;
}
span.validate-error {
  display: block;
}


// 頑張ってCSSでバリデーションエラーを上にもっていこうとした痕跡
// 必要な医かもだが一応念のため残しておく
//@include mq-s() {
//  .common-dl {
//    dd {
//      display: flex;
//      flex-direction: column-reverse;
//      > span {
//        display: flex;
//        flex-direction: column-reverse;
//      }
//      .registration--from__birthday-list {
//        li > span {
//          display: flex;
//          flex-direction: column-reverse;
//          white-space: nowrap;
//          font-size: 10px;
//        }
//      }
//    }
//    .validate-error {
//      margin-top: 12px;
//      margin-bottom: 0px;
//    }
//  }
//}

.form-password {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;

  &.inline {
    width: auto;
    flex: 1;
  }

  &__input {
    border-right: none !important;
    padding-right: 0 !important;

    @include mq-mb() {
      &::placeholder {
        font-size: 12px;
      }
    }
  }

  &__append {
    display: flex;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    transition: all 0.1s;
    background-color: $C_WHITE;
    border-top: 1px solid $C_BLACK_TRANS_40;
    border-right: 1px solid $C_BLACK_TRANS_40;
    border-bottom: 1px solid $C_BLACK_TRANS_40;

    &__icon {
      cursor: pointer;
      width: 16px;
      height: 16px;

      fill: $C_GRAY3;
    }
  }
}
