.input-group-row {
  display: flex;

  .input {
    width: 45%;
    margin-right: 8px;
  }
}

.convenience-store-type-error {
  margin-left: 230px;
  margin-top: -30px;
}

.zipcode-input {
  position: relative;
  display: inline-block;

  .autocomplete-container {
    padding: 0;
    position: absolute;
    border: 1px solid #d4d4d4;
    z-index: 99;
    left: 0;
    right: 0;

    &:has(div) {
      border: 1px solid #d4d4d4;
    }

    .autocomplete-item {
      padding: 10px;
      cursor: pointer;
      background-color: #fff;
      border-bottom: 1px solid #d4d4d4;

      &:hover {
        background-color: #e9e9e9;
      }
    }
  }
}

.alert {
  padding: 20px;
  background-color: $C_RED;
  color: white;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  right: 0;

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0s;
  }

  .closebtn:hover {
    color: black;
  }
}

.border-alert {
  border: 2px solid $C_RED !important;
  border-collapse: collapse;

  ::-ms-tooltip {
    content: "HEllleeoeo";
  }
}

.border-th {
  border-right: 0 !important;
}

.border-td {
  border-left: 0 !important;
}
