/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.artist-profile {
  position: relative;
  padding-top: 55%;

  @include mq-mb {
    padding-top: 65%;
  }

  &--wide {
    padding-top: 505px;

    @include mq-s {
      padding-top: 50%;
    }
  }

  &__bio {
    box-sizing: content-box;
    & strong {
      font-weight: bold;
    }
  }

  &_wrapper {
    position: relative;
    margin-top: 120px;

    @include mq-s {
      margin-top: 80px;
    }

    .registered_trademark {
      //content: "Bromide.jp\0ae. Photo Produce";
      font-family: $ENG_FONT_FAMILY;
      font-size: 2rem;
      transform: rotate(-90deg);
      position: absolute;
      bottom: 200px;
      right: -50px;

      .reg_mark {
        vertical-align: top;
        font-size: 1.2rem;
      }

      @include mq-s {
        font-size: 2rem;
        bottom: -6px;
        right: 0;
        transform: rotate(0);
      }
    }
  }

  &__photo {
    width: 100%;
    padding-top: 55%;
    position: absolute;
    top: -100px;
    right: 0;

    &.image_unloaded {
      @include loadingBb($LOADING_ICON_ARTIST_PHOTO_SIZE);
    }

    @include mq-mb {
      padding-top: 65%;
    }

    @include mq-s {
      top: -50px;
    }

    .kv-img {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      object-fit: contain;
      filter: drop-shadow(0 20px 20px rgba(0,0,0,.2));

      @include mq-mb {
        //top: 50%;
        //transform: translateY(-50%);
        //max-height: 100%;
        //max-width: 100%;
        //height: auto;
      }
    }

    &--full {
      .kv-img {
        width: 100%;
      }
    }
  }

  &__name_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__name {
    white-space: nowrap;
    line-height: 1;
    font-weight: 700;

    @include mq-mb {
      bottom: -10%;
    }
    @include mq-s {
      font-size: 4rem;
    }

  }

  &__logo {
    position: absolute;
    left: 0;
    bottom: 30%;

    @include mq-mb {
      bottom: -10%;
    }

    img {
      position: relative;
      max-height: 100px;

      @include mq-m {
        max-height: 80px;
      }
      @include mq-s {
        max-height: 60px;
      }
    }

    &--text {
      display: block;
      padding-top: 20px;
      font-size: 1.8rem;
      line-height: 1.5;
      font-weight: 700;

      @include mq-mb {
        display: none;
      }
    }
  }

  //&__bio {
  //  @include keep-font-size(15, 13, 0, 20);
  //}

  &__news {
    .information {
      &__title--date  {
        //@include keep-font-size(15, 15, 0, 117.75)
      }
      &__subtitle {
        //@include keep-font-size(14, 13, 0, 117.75)
      }
      &__detail {
        //@include keep-font-size(15, 14, 0, 42)
      }
    }
  }
}

/* ========================================
    バナー
======================================== */
.top-banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 0 20px;

  @include mq-s {
    padding: 30px 0 20px;
    justify-content: center;
  }

  &__item {
    width: 49%;
    margin-bottom: 20px;
    text-align: center;
    &.image_unloaded {
      @include loadingBb($LOADING_ICON_BANNER_SIZE);
    }

    @include mq-s {
      width: 90%;
      margin: 0 auto 10px;

    }
  }
}

/* ========================================
    利用規約
======================================== */
.agreement {
  position: absolute;
  height: calc(100% + 25px);
  width: calc(100% + 50px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  text-align: center;
  top: -25px;
  left: -25px;
  right: -25px;
  padding: 50px 30px;

  @include mq-s {
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    left: -10px;
    right: -10px;
    padding: 30px 20px;
  }
}

.term-agreement {
  font-size: 14px;
}
