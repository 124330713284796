/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special

======================================== */

// ========================================
//  class
// ========================================
// 一番普通のボタンの共通スタイル
%normal-btn {
  border: 2px solid $C_BLACK !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 16px 5px;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.25;
  font-weight: 700;
  background-color: $C_WHITE;
  color: $C_BLACK;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  @include mq-m {
    font-size: 1.6rem;
    line-height: 1.25;
  }
  @include mq-s {
    font-size: 1.45rem;
    line-height: 1.25;
  }
}

// 目立たせたいボタンの共通スタイル
%special-btn {
  border: 2px solid $C_BLACK !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 16px 5px;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.25;
  font-weight: 700;
  background-color: $C_RED;
  color: $C_WHITE;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  @include mq-m {
    font-size: 1.6rem;
    line-height: 1.25;
  }
  @include mq-s {
    font-size: 1.45rem;
    line-height: 1.25;
  }
}

// 戻るなどのネガティブボタンの共通スタイル
%none-btn {
  border: 2px solid $C_GRAY2 !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 16px 5px;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.25;
  font-weight: 700;
  background-color: $C_WHITE;
  color: $C_GRAY2;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  @include mq-m {
    font-size: 1.6rem;
    line-height: 1.25;
  }
  @include mq-s {
    font-size: 1.45rem;
    line-height: 1.25;
  }
}


// 幅いっぱいのサイズ
%btn-100 {
  display: block;
  width: 95%;
  max-width: 420px;
  margin: 10px auto;
}

// ２つ並べられるサイズ
%btn-50 {
  display: inline-block;
  width: 48%;
  background-color: $C_WHITE;
  color: $C_BLACK;
  margin: 10px 0.5%;
  max-width: 420px;
}


/* ========================================
    normal
        よく使うボタン
======================================== */

.btn-100,
input.btn-100 {
  @extend %normal-btn;
  @extend %btn-100;

  &--red {
    @extend %special-btn;
    @extend %btn-100;
  }

  &--none {
    @extend %none-btn;
    @extend %btn-100;
  }
}

.btn-50,
input.btn-50 {
  @extend %normal-btn;
  @extend %btn-50;
}
